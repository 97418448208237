import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import styled from "styled-components"

import bgVideo from "../assets/bgvideo.mp4"
import bgPoster from "../assets/bgposter.jpg"

import delosperma from "../images/photos/delosperma-fire-spinner.jpg"
import { Gallery } from "../components/gallery"

export const VideoWrapper = styled.div`
  @keyframes overlay {
    00% {
      background-color: hsla(250, 82%, 30%, 0.7);
    }
    50% {
      background-color: hsla(278, 82%, 30%, 0.7);
    }
    100% {
      background-color: hsla(300, 82%, 30%, 0.7);
    }
  }

  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    animation: overlay 10s alternate;
    animation-iteration-count: infinite;
  }

  > video {
    mix-blend-mode: multiply;

    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const BackgroundVideo = () => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.playbackRate = 0.75
    }
  }, [])
  return (
    <VideoWrapper>
      <video loop muted autoPlay={true} playsInline={true} ref={ref} poster={bgPoster}>
        <source src={bgVideo} />
      </video>
    </VideoWrapper>
  )
}

export const GridElement = styled.div`
  position: relative;

  display: block;
  margin-left: auto;
  margin-right: auto;

  max-width: 1400px;
  padding-left: 15px;
  padding-right: 15px;
`

const HeadlineWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15vh;

  > p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    line-height: 1.3;

    font-size: 1rem;
    line-height: 1.4;
    @media only screen and (min-width: 600px) {
      font-size: 1.25rem;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }

    color: white;

    @media only screen and (min-width: 600px) {
      max-width: 40em;
    }

    a {
      color: inherit;
    }
  }
`

const StyledButtonAsAnchor = styled.a`
  border-radius: 4px;
  padding: 0.625rem 1.25rem;
  background-color: hsl(340, 75%, 52%);

  &:hover {
    background-color: hsl(340, 80%, 56%);
  }

  text-decoration: none;
`

const Button = props => {
  return <StyledButtonAsAnchor href={props.href}>{props.children}</StyledButtonAsAnchor>
}

const H1 = styled.h1`
  color: white;
  line-height: 1.4;

  font-size: 2rem;
  @media only screen and (min-width: 600px) {
    font-size: 4rem;
  }

  font-family: Comfortaa;
  font-weight: 700;

  text-transform: uppercase;
`

const Span = styled.span`
  color: #f2daff;

  margin-bottom: 2rem;
  @media only screen and (min-width: 600px) {
    margin-top: -0.5em;
    margin-bottom: 5rem;
  }

  text-align: center;
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StrongSpan = styled.strong`
  background-color: hsla(279, 88%, 35%, 1);
  padding: 0.25rem 0.375rem;
  color: white;

  border-radius: 8px;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Start" lang={"pl"} />
    <div style={{ position: "relative", height: "90vh" }}>
      <BackgroundVideo style={{ position: "absolute" }} />

      <GridElement>
        <HeadlineWrapper>
          <H1>Bylinarium</H1>
          <Span>zwariowani na punkcie bylin.</Span>
          <p>
            W ramach Bylinarium zaprezentujemy bogatą i szeroką ofertę bylin, w której znajdą się zarówno
            najpopularniejsze byliny jak i nowości oraz rzadko spotykane odmiany. Wszystko najwyższej jakości.
          </p>

          <p>
            Subskrybenci newslettera otrzymają dostęp do oferty jako pierwsi, a każdy subskrybent otrzyma za darmo swój{" "}
            <StrongSpan>kod rabatowy</StrongSpan> do wykorzystania na dowolne byliny.
          </p>
          <p>
            <FlexCenter>
              <Button href="https://landing.mailerlite.com/webforms/landing/a1i9k5">Zasubskrybuj newsletter</Button>
            </FlexCenter>
          </p>
        </HeadlineWrapper>
      </GridElement>
    </div>
    <Gallery />
  </Layout>
)

export default IndexPage
