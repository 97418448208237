import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const database = {
  "achillea-millefolium-purpurglut": {
    latin: "Achillea millefolium",
    polish: "Krwawnik pospolity",
    variety: "Purpurglut",
    id: 972,
  },
  "armeria-maritima-splendens": {
    latin: "Armeria maritima",
    polish: "Zawciąg pospolity",
    variety: "Splendens",
    id: 973,
  },
  "astilbe-x-arendsii-gloria-purpurea": {
    latin: "Astilbe x arendsii",
    polish: "Tawułka Andersa",
    variety: "Gloria Purpurea",
    id: 974,
  },
  "carex-comans-bronze-form": { latin: "Carex comans", polish: "Turzyca włosowa", variety: "Bronze form", id: 752 },
  "cerastium-tomentosum-silberteppich": {
    latin: "Cerastium tomentosum",
    polish: "Rogownica kutnerowata",
    variety: "Silberteppich",
    id: 895,
  },
  "phlox-paniculata-queen": { latin: "Phlox paniculata", polish: "Floks wiechowaty", variety: "Queen", id: 975 },
  "phlox-paniculata-bright-eyes": {
    latin: "Phlox paniculata",
    polish: "Floks wiechowaty",
    variety: "Bright Eyes",
    id: 976,
  },
  "phlox-paniculata-flame-blue": {
    latin: "Phlox paniculata",
    polish: "Floks wiechowaty",
    variety: "Flame blue",
    id: 917,
  },
  "geranium-himalayense-gravetye": {
    latin: "Geranium himalayense",
    polish: "Bodziszek himalajski",
    variety: "Gravetye",
    id: 977,
  },
  "primula-elatior": { latin: "Primula elatior", polish: "Pierwiosnek wyniosły", variety: null, id: 725 },
  "veronicastrum-virginicum-fascination": {
    latin: "Veronicastrum virginicum",
    polish: "Przetacznikowiec wirginijski",
    variety: "Fascination",
    id: 978,
  },
  "coreopsis-rosea-american-dream": {
    latin: "Coreopsis rosea",
    polish: "Nachyłek różowy",
    variety: "American Dream",
    id: 754,
  },
  "delosperma-fire-spinner": { latin: "Delosperma", polish: "Słonecznica", variety: "Fire Spinner", id: 979 },
  "thymus-serpyllum-alba": { latin: "Thymus serpyllum", polish: "Macierzanka piaskowa", variety: "Albus", id: 924 },
}

const GalleryBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
`

const GalleryBoxElement = styled.div`
  width: 100%;
  padding: 0px;

  position: relative;
`

const GalleryBoxElementDescription = styled.span`
  position: absolute;
  bottom: 8px;

  left: 3%;
  width: 94%;

  background: #efebf1f5;

  z-index: 1;
  text-align: center;
  padding: 8px 4px;
  font-size: 16px;

  strong {
    font-size: 18px;
  }
`

export function Gallery() {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { regex: "/photos/" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  function formatName(entry) {
    if (!entry) {
      return ""
    }

    const varietyPart = entry.variety ? ` '${entry.variety}'` : ""

    return `${entry.latin}${varietyPart}`
  }

  function formatPolishName(entry) {
    if (!entry) {
      return ""
    }

    return `${entry.polish}`
  }

  return (
    <div style={{ backgroundColor: "rgb(216, 206, 222)" }}>
      <GalleryBox>
        {data.images.nodes.map(e => {
          return (
            <GalleryBoxElement>
              <GalleryBoxElementDescription>
                <strong>{formatName(database[e.name])}</strong>
                <span style={{ display: "block" }}>{formatPolishName(database[e.name])}</span>
              </GalleryBoxElementDescription>
              <Img fluid={e.childImageSharp.fluid} alt="Gatsby Docs are awesome" />
            </GalleryBoxElement>
          )
        })}
      </GalleryBox>
    </div>
  )
}
